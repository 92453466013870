var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "px-6 py-6 mt-10", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { lg: "8" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { lg: "4", cols: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "border-radius-xl card-shadow py-2 mb-lg-0 mb-6",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "\n                  bg-gradient-success\n                  shadow-success\n                  border-radius-lg\n                  mx-4\n                  py-2\n                  mt-n8\n                ",
                                },
                                [
                                  _c("div", { staticClass: "chart" }, [
                                    _c("canvas", {
                                      staticClass: "chart-canvas",
                                      attrs: {
                                        id: "chart-line-1",
                                        height: "100",
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                              _c("div", { staticClass: "px-6 pb-0 pt-4" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "\n                    text-sm\n                    mb-0\n                    text-capitalize\n                    font-weight-light\n                    text-body\n                  ",
                                  },
                                  [_vm._v(" Visitors ")]
                                ),
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "font-weight-bolder text-h5 text-typo mb-0",
                                  },
                                  [
                                    _vm._v(" 5,927 "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-success text-sm font-weight-bolder",
                                      },
                                      [_vm._v("+55%")]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { lg: "4", cols: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "border-radius-xl card-shadow py-1 mb-lg-0 mb-6",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "\n                  bg-gradient-primary\n                  shadow-primary\n                  border-radius-lg\n                  mx-4\n                  py-2\n                  mt-n8\n                ",
                                },
                                [
                                  _c("div", { staticClass: "chart" }, [
                                    _c("canvas", {
                                      staticClass: "chart-canvas",
                                      attrs: {
                                        id: "chart-line-2",
                                        height: "100",
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                              _c("div", { staticClass: "px-6 pb-0 pt-4" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "\n                    text-sm\n                    mb-0\n                    text-capitalize\n                    font-weight-light\n                    text-body\n                  ",
                                  },
                                  [_vm._v(" Income ")]
                                ),
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "font-weight-bolder text-h5 text-typo mb-0",
                                  },
                                  [
                                    _vm._v(" $130,832 "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-success text-sm font-weight-bolder",
                                      },
                                      [_vm._v("+90%")]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { lg: "4" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "border-radius-xl card-shadow h-100 py-1 mb-lg-0 mb-6",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "\n                  bg-gradient-default\n                  shadow-dark\n                  border-radius-lg\n                  mx-4\n                  py-2\n                  mt-n8\n                ",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "\n                    px-4\n                    py-4\n                    d-flex\n                    flex-column\n                    justify-center\n                    text-center\n                    h-100\n                  ",
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "text-decoration-none",
                                          attrs: { href: "javascript:;" },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "material-icons-round text-white mb-2 mt-2",
                                              attrs: { size: "20" },
                                            },
                                            [_vm._v("add")]
                                          ),
                                          _c(
                                            "h6",
                                            {
                                              staticClass:
                                                "text-h6 text-white font-weight-light mb-3",
                                            },
                                            [_vm._v(" New tab ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "px-6 pt-4 text-center" },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mb-0 text-sm text-body font-weight-light",
                                    },
                                    [
                                      _vm._v(
                                        " Press the button above and complete the new tab data. "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "mb-lg-0 mb-6" },
                        [_c("calendar")],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { lg: "4" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { lg: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "card-shadow px-4 py-1 border-radius-xl",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "\n                  overflow-hidden\n                  position-relative\n                  border-radius-lg\n                  h-100\n                  mt-n8\n                  shadow-dark\n                ",
                                  style:
                                    "background-image: url(" +
                                    require("../../assets/img/ivancik.jpg") +
                                    "); background-size: cover;",
                                },
                                [
                                  _c("span", {
                                    staticClass:
                                      "mask bg-gradient-default opacity-8",
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "px-4 py-4 position-relative z-index-1 h-100",
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "text-white text-h6 font-weight-bold mb-4",
                                        },
                                        [_vm._v(" Hey John! ")]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-white font-weight-light mb-4",
                                        },
                                        [
                                          _vm._v(
                                            " Wealth creation is an evolutionarily recent positive-sum game. It is all about who take the opportunity first. "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "mx-2" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "ms-auto font-weight-bolder py-4 px-4 mt-5 mb-4",
                                      attrs: {
                                        outlined: "",
                                        color: "#344767",
                                        small: "",
                                        rounded: "",
                                      },
                                    },
                                    [
                                      _vm._v(" Read more "),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "material-icons-round ms-2",
                                          attrs: { size: "14" },
                                        },
                                        [_vm._v("chevron_right")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "mb-6", attrs: { lg: "12", md: "6" } },
                        [_c("categories-card")],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { lg: "12", md: "6" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "card-shadow border-radius-xl" },
                            [
                              _c(
                                "div",
                                { staticClass: "px-4 pt-4" },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c(
                                            "v-avatar",
                                            {
                                              staticClass:
                                                "w-100 h-100 shadow border-radius-lg mt-n10",
                                              attrs: {
                                                width: "auto",
                                                height: "auto",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "w-100",
                                                attrs: {
                                                  src: require("@/assets/img/kal-visuals-square.jpg"),
                                                  alt: "Avatar",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "8" } },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-muted text-sm font-weight-light",
                                            },
                                            [
                                              _vm._v(
                                                " Today is Mike's birthday. Wish her the best of luck! "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "\n                        text-uppercase\n                        btn-default\n                        bg-gradient-default\n                        py-1\n                        px-4\n                        me-2\n                      ",
                                              attrs: {
                                                elevation: "0",
                                                ripple: false,
                                                height: "34",
                                                color: "#5e72e4",
                                                small: "",
                                              },
                                            },
                                            [_vm._v("Send Message")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { sm: "6" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl" },
                    [
                      _c(
                        "div",
                        { staticClass: "px-4 pt-4" },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { md: "6" } }, [
                                _c(
                                  "h6",
                                  {
                                    staticClass:
                                      "mb-0 text-h6 font-weight-bold text-typo",
                                  },
                                  [_vm._v(" Transactions ")]
                                ),
                              ]),
                              _c(
                                "v-col",
                                { staticClass: "text-end", attrs: { md: "6" } },
                                [
                                  _c("v-icon", [
                                    _vm._v("far fa-calendar-alt me-2"),
                                  ]),
                                  _c("small", { staticClass: "text-body" }, [
                                    _vm._v("23 - 30 March 2021"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "px-4 pb-4 pt-2" },
                        _vm._l(_vm.transactions, function (transaction, index) {
                          return _c(
                            "div",
                            { key: transaction.title },
                            [
                              _c(
                                "v-list-item",
                                {
                                  key: transaction.title,
                                  staticClass: "px-0 py-4",
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "me-4",
                                      attrs: {
                                        ripple: false,
                                        icon: "",
                                        outlined: "",
                                        rounded: "",
                                        color: transaction.color,
                                        width: "34px",
                                        height: "34px",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "material-icons-round",
                                          attrs: { size: "14" },
                                        },
                                        [_vm._v(_vm._s(transaction.icon))]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "py-0" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-column" },
                                        [
                                          _c(
                                            "h6",
                                            {
                                              staticClass:
                                                "mb-1 text-typo text-sm font-weight-bold",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(transaction.title) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "text-xs text-body",
                                            },
                                            [_vm._v(_vm._s(transaction.date))]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "py-0 text-end" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-sm font-weight-bold text-gradient",
                                          class: transaction.gradient,
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(transaction.value) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("hr", {
                                staticClass: "horizontal",
                                class: {
                                  dark: index != _vm.transactions.length - 1,
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { sm: "6" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl" },
                    [
                      _c(
                        "div",
                        { staticClass: "px-4 pt-4" },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { md: "6" } }, [
                                _c(
                                  "h6",
                                  {
                                    staticClass:
                                      "mb-0 text-h6 font-weight-bold text-typo",
                                  },
                                  [_vm._v(" Revenue ")]
                                ),
                              ]),
                              _c(
                                "v-col",
                                { staticClass: "text-end", attrs: { md: "6" } },
                                [
                                  _c("v-icon", [
                                    _vm._v("far fa-calendar-alt me-2"),
                                  ]),
                                  _c("small", { staticClass: "text-body" }, [
                                    _vm._v("01 - 07 June 2021"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "px-4 pb-4 pt-2" },
                        _vm._l(_vm.revenues, function (revenue, index) {
                          return _c(
                            "div",
                            { key: revenue.title },
                            [
                              _c(
                                "v-list-item",
                                {
                                  key: revenue.title,
                                  staticClass: "px-0 py-4",
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "me-4",
                                      attrs: {
                                        ripple: false,
                                        icon: "",
                                        outlined: "",
                                        rounded: "",
                                        color: revenue.color,
                                        width: "34px",
                                        height: "34px",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "material-icons-round",
                                          attrs: { size: "14" },
                                        },
                                        [_vm._v(_vm._s(revenue.icon))]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "py-0" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-column" },
                                        [
                                          _c(
                                            "h6",
                                            {
                                              staticClass:
                                                "mb-1 text-typo text-sm font-weight-bold",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(revenue.title) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "text-xs text-body",
                                            },
                                            [_vm._v(_vm._s(revenue.date))]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "py-0 text-end" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-sm font-weight-bold text-gradient",
                                          class: revenue.gradient,
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(revenue.value) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("hr", {
                                staticClass: "horizontal",
                                class: {
                                  dark: index != _vm.transactions.length - 1,
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }